@import url(https://fonts.googleapis.com/css2?family=Barlow:wght@100;300;400&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.DashboardNavbar_top_nav__iq3Du{height:5rem;width:100vw;padding-right:5rem;display:flex;justify-content:flex-end;align-items:center}

.NavbarWrapper_content_wrapper__1N-x3{padding:5rem 5rem}

.GlobalStyles_layout_content__1m4dd{padding:2rem;background-color:white}.GlobalStyles_layout__1yQDU{min-height:85vh}

.FileThumbnailComponent_thumbnail__67WaM{height:10rem;width:10rem;display:inline-block;margin:1rem;border-radius:3px;background-color:#f0f2f5;text-align:center;overflow-wrap:break-word;overflow:hidden}.FileThumbnailComponent_thumbnail__67WaM:hover{background-color:#bebebe;cursor:pointer}.FileThumbnailComponent_thumbnail__67WaM .FileThumbnailComponent_display__3D7WA{padding:.5rem 1rem;height:10rem;width:10rem}.FileThumbnailComponent_thumbnail__67WaM .FileThumbnailComponent_actions__3Diem{padding:.5rem 1rem;display:flex;justify-content:space-around;align-items:center;height:10rem;width:10rem;z-index:10000}.FileThumbnailComponent_thumbnail__67WaM .FileThumbnailComponent_actions__3Diem .FileThumbnailComponent_download_icon__5ThWF{font-size:2rem;color:#40a9ff}.FileThumbnailComponent_thumbnail__67WaM .FileThumbnailComponent_actions__3Diem .FileThumbnailComponent_download_icon__5ThWF:hover{color:#0069c0}.FileThumbnailComponent_thumbnail__67WaM .FileThumbnailComponent_actions__3Diem .FileThumbnailComponent_delete_icon__2Nmn_{font-size:2rem;color:Red}.FileThumbnailComponent_thumbnail__67WaM .FileThumbnailComponent_actions__3Diem .FileThumbnailComponent_delete_icon__2Nmn_:hover{color:maroon}.FileThumbnailComponent_thumbnail__67WaM .FileThumbnailComponent_icon__3zskV{font-size:4rem}

.FileListDisplay_thumbnails__32ZTq{width:100%}

.NotesDisplayTable_comment__2ZHQg{background-color:#f0f2f5;margin-bottom:1rem;padding:1rem}

.LoginView_login_layout__1PaMD{height:100vh}.LoginView_row__2SAsp{height:100vh}.LoginView_login_card__Cn2i7{width:20rem}

.RegisterView_login_layout__xm0O5{height:100vh}.RegisterView_row__1AIA2{height:100vh}.RegisterView_login_card__dIQdB{width:30rem;min-height:30rem}

.ForgotPassword_form_layout__1HK76{height:100vh}.ForgotPassword_row__296Wk{height:100vh}.ForgotPassword_form_card__25Vws{width:20rem}

*{font-family:'Barlow', sans-serif}.LandingPage_navbar__2FTB_{background-color:black !important;color:#001529;padding:0}.LandingPage_navbar_menu__22lVu{background-color:lightgrey;padding:0 200px}.LandingPage_left_jumbotron__24AXL{background-color:#001529;color:lightgrey;padding:5% 15%}.LandingPage_left_jumbotron__24AXL .LandingPage_title__3VDKI{margin-top:2rem;font-size:4rem;color:lightgrey;font-weight:normal}.LandingPage_left_jumbotron__24AXL .LandingPage_about__1ZxhR{font-size:1rem;font-weight:100;font-weight:normal}.LandingPage_left_jumbotron__24AXL .LandingPage_list_item__3XqL2{font-size:1.25rem;margin-bottom:.5rem}.LandingPage_left_jumbotron__24AXL .LandingPage_list_item__3XqL2:last-child{margin-bottom:3rem}.LandingPage_image__3A5S1{width:100%;background-image:url(/static/media/landing.418c627e.jpg)}.LandingPage_card__1OkfM{text-align:center;margin-bottom:3rem;margin-top:3rem}.LandingPage_card__1OkfM .LandingPage_header__VAPWs{font-weight:300;font-size:2rem}.LandingPage_card__1OkfM .LandingPage_icon__2ztMR{font-size:10rem}.LandingPage_card__1OkfM .LandingPage_description__1EOBH{font-size:1rem}.LandingPage_contact_section__1JyPv{background-color:#001529;color:white;min-height:20rem;padding:5rem;text-align:center}.LandingPage_contact_section__1JyPv .LandingPage_header__VAPWs{color:white;font-weight:300}


