.login_layout{
    height: 100vh;
}

.row{
    height: 100vh;
    // width: 50vw;
}

.login_card{
    width: 30rem;
    min-height: 30rem;
}