.form_layout{
    height: 100vh;
}

.row{
    height: 100vh;
}

.form_card{
    width: 20rem;
}