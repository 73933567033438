@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@100;300;400&display=swap');
* {
    font-family: 'Barlow', sans-serif;
}
.navbar{
    background-color: black !important;
    color: #001529;    
    padding: 0;

    &_menu{
        background-color: lightgrey;  
        padding: 0 200px;
    }
}
.left_jumbotron{
    background-color: #001529;
    color: lightgrey;
    padding: 5% 15%;

    .title {
        margin-top: 2rem;
        font-size: 4rem;
        color: lightgrey;
        font-weight: normal;

    }
    .about {
        font-size: 1rem;
        font-weight: 100;
        font-weight: normal;

    }
    .list_item {
        font-size: 1.25rem;
        margin-bottom: .5rem;

        &:last-child {
            margin-bottom: 3rem;
        }
    }
}
.image {
    width: 100%;
    background-image: url('../../../assets/landing.jpg');
}

.card {
    // height: 10rem;
    text-align: center;
    margin-bottom: 3rem;
    margin-top: 3rem;
    .header{
        font-weight: 300;
        font-size: 2rem;

        }
    .icon {
        font-size: 10rem;   
    }
    .description {
        font-size: 1rem;
    } 
}

.contact_section {
    background-color: #001529;
    color: white;
    min-height: 20rem;
    padding: 5rem;
    text-align: center;
    
    .header {
        color: white;
        font-weight: 300;
    }
}