.login_layout{
    height: 100vh;
}

.row{
    height: 100vh;
}

.login_card{
    width: 20rem;
}