.thumbnail{
    // border: 1px solid #444444;
    height: 10rem;
    width: 10rem;
    display: inline-block;
    margin: 1rem;
    border-radius: 3px;
    background-color: #f0f2f5;
    text-align: center;
    // padding: .5rem 1rem;
    overflow-wrap: break-word;
    overflow: hidden;

    &:hover{
        background-color: #bebebe;
        cursor: pointer;
    }

    .display {
        padding: .5rem 1rem;
        height: 10rem;
        width: 10rem;
    }

    .actions {
        padding: .5rem 1rem;
        // position: absolute;
        // top: -10rem;
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 10rem;
        width: 10rem;
        z-index: 10000;

        .download_icon {
            font-size: 2rem;
            color: #40a9ff;

            &:hover{
                color: darken(#40a9ff, 25%);
            }
        }
        .delete_icon {
            font-size: 2rem;
            color: Red;
            &:hover{
                color: darken(red, 25%);
            }
        }
    }

    .icon {
        font-size: 4rem;
    }
    .name {
        
    }
}